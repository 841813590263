<template>
  <div>
    <vx-card class="mt-8">
      <div class="card-title">
        <h2 class="mb-3">{{ LabelConstant.headingLabelTellusAboutYourCustomers }}</h2>
        <p>{{ LabelConstant.infoLabelMyCustomers }}</p>
      </div>
      <div class="common-table">
        <div class="table-row-select">
          <vs-button title="Import Customers" color="primary" class="ml-auto" type="filled" @click="ImportPopup = true">
            {{ LabelConstant.buttonLabelImportCustomers }}
          </vs-button>
        </div>
        <vs-table search :data="customers" :max-items="5" pagination>
          <template slot="thead">
            <vs-th></vs-th>
            <vs-th sort-key="DomainName">{{ LabelConstant.tableHeaderLabelDomainName }}</vs-th>
            <vs-th sort-key="CompanyName">{{ LabelConstant.tableHeaderLabelCompanyName }}</vs-th>
            <vs-th sort-key="Date">{{ LabelConstant.tableHeaderLabelDate }}</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td width="90">
                <div class="table-action">
                  <vs-button title="Edit" color="primary" type="filled" icon-pack="feather" icon="icon-edit" @click="editCustomer(tr)" class="mr-2 float-left"></vs-button>
                  <vs-button @click="deleteConfirmation(tr)" title="Delete" color="danger" type="filled" icon-pack="feather" icon="icon-trash-2" class="mr-0"></vs-button>
                </div>
              </vs-td>
              <vs-td :data="data[indextr].Domain_Name">{{ data[indextr].Domain_Name }}</vs-td>
              <vs-td :data="data[indextr].Company_Name">{{ data[indextr].Company_Name }}</vs-td>
              <vs-td :data="data[indextr].Date">{{ data[indextr].Date | formatDate }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vx-card>
    <!-- Import Customers modal -->
    <vs-popup class="popup-width" title="Import Customers" :active.sync="ImportPopup">
      <template>
        <div class="vx-row find-people">
          <div class="vx-col w-full">
            <form data-vv-scope="importCustomerForm">
              <div class="vx-row">
                <div class="vx-col w-full">
                  <vx-card class="">
                    <p class="mb-3">{{ LabelConstant.inputLabelInfo }}</p>
                    <vs-textarea class="form-textarea" row="5" :placeholder="LabelConstant.inputPlaceHolderDomainName" v-model="domainNames" name="domainNames" v-validate="'required'"/>
                    <span class="text-danger text-sm">{{ errors.first("importCustomerForm.domainNames") }}</span>
                  </vx-card>
                </div>
              </div>
            </form>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button color="primary" type="filled" @click="importCustomer()">{{ LabelConstant.buttonLabelImportCustomers }}</vs-button>
        <vs-button color="grey" @click="cancelImportCustomer()" type="border" class="ml-4 grey-btn" >{{ LabelConstant.buttonLabelCancel }}</vs-button>
      </div>
    </vs-popup>
    <!-- Edit Customer modal -->
    <vs-popup class="popup-width" title="Edit Customer" :active.sync="EditPopup">
      <template>
        <div class="vx-row find-people">
          <div class="vx-col w-full">
            <form data-vv-scope="updateCustomerform">
              <vx-card class="">
                <div class="vx-row">
                  <div class="vx-col w-full mb-4">
                    <label>{{ LabelConstant.inputLabelCompanyName }}</label>
                    <vs-input type="text" class="w-full" v-model="companyName" name="companyName" />
                  </div>
                  <div class="vx-col w-full">
                    <label>{{ LabelConstant.inputLabelDomainName }}</label>
                    <vs-input type="text" class="w-full" v-model="domainName" name="domainName" v-validate="'required'" />
                    <span class="text-danger text-sm">{{ errors.first("updateCustomerform.domainName") }}</span>
                  </div>
                </div>
              </vx-card>
            </form>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button color="primary" type="filled" @click="updateCustomer()" >{{ LabelConstant.buttonLabelSaveCustomers }}</vs-button>
        <vs-button color="grey" @click="cancelEditCustomer()" type="border" class="ml-4 grey-btn" >{{ LabelConstant.buttonLabelCancel }}</vs-button>
      </div>
    </vs-popup>
    <!-- Delete Popup -->
    <vs-popup class="delete-popup" :active.sync="deletepopupActive">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="Trash2Icon"></feather-icon>
            </div>
            <h4>{{ LabelConstant.popupHeadingLabelDeleteCustomer }}</h4>
            <span class="deleted-no">{{ domainName ? domainName : "" }}</span>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button type="filled" @click="deleteCustomer()" color="danger">{{ LabelConstant.buttonLabelDelete }}</vs-button>
        <vs-button @click="deletepopupActive = false,domainName = null,customerId = 0" color="grey" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import vSelect from "vue-select";
import moment from "moment";
import { Validator } from "vee-validate";
const dict = {
  custom: {
    domainNames: {
      required: "Please enter domain names",
    },
    domainName: {
      required: "Please enter domain name",
    },
  },
};
Validator.localize("en", dict);
export default {
  components: {
    VxCard,
    vSelect,
  },
  data() {
    return {
      ImportPopup: false,
      EditPopup: false,
      domainNames: "",
      customers: [],
      customerId: 0,
      domainName: null,
      companyName: null,
      editDate: null,

      //delete
      deletepopupActive: false,
    };
  },
  created() {
    this.getCustomersList();
  },
  filters: {
    formatDate(val) {
      return moment(val).format("MMM DD, YYYY");
    },
  },
  methods: {
    //get customer list
    async getCustomersList() {
      this.$vs.loading();
      await this.axios
        .get("/ws/FindAnyoneCustomer/GetCustomers")
        .then((response) => {
          let data = response.data;
          this.customers = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    // import customers api call
    importCustomer() {
      this.$validator.validateAll("importCustomerForm").then((result) => {
        if (result) {
          this.$vs.loading();
          let input = {
            DomainNames: this.domainNames.split("\n"),
          };
          this.axios
            .post("/ws/FindAnyoneCustomer/ImportCustomerList", input)
            .then(() => {
              this.domainNames = "";
              this.ImportPopup = false;
              this.$vs.notify({
                title: "Success",
                text: "Customer imported successfully.",
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000,
              });
              setTimeout(() => {
                this.errors.clear();
              }, 100);
              this.getCustomersList();
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
              this.$vs.notify({
                title: "Erorr",
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000,
              });
            });
        }
      });
    },
    // show data to in popup to update
    editCustomer(data) {
      this.customerId = data.Id;
      this.companyName = data.Company_Name;
      this.domainName = data.Domain_Name;
      this.editDate = data.Date;
      this.EditPopup = true;
    },
    // update customer api call 
    updateCustomer() {
      this.$validator.validateAll("updateCustomerform").then((result) => {
        if (result) {
          this.$vs.loading();
          let input = {
            Id: this.customerId,
            Domain_Name: this.domainName,
            Company_Name: this.companyName,
            Date: this.editDate,
          };
          this.axios
            .post("/ws/FindAnyoneCustomer/EditCustomer", input)
            .then(() => {
              this.cancelEditCustomer();
              this.$vs.notify({
                title: "Success",
                text: "Customer updated successfully.",
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000,
              });
              setTimeout(() => {
                this.errors.clear();
              }, 100);
              this.getCustomersList();
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.showError(e);
              this.$vs.notify({
                title: "Erorr",
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000,
              });
            });
        }
      });
    },
    // cancel import customer
    cancelImportCustomer() {
      this.ImportPopup = false;
      this.domainNames = "";
      setTimeout(() => {
        this.errors.clear();
      }, 100);
    },
    // cancel edit customer
    cancelEditCustomer() {
      this.customerId = 0;
      this.companyName = null;
      this.domainName = null;
      this.EditPopup = false;
        setTimeout(() => {
        this.errors.clear();
      }, 100);
    },
    // delete confirmation popup
    deleteConfirmation(data) {
      this.domainName = data.Domain_Name;
      this.customerId = data.Id;
      this.deletepopupActive = true;
    },
    // delete customer api call
    async deleteCustomer() {
      this.$vs.loading();
      await this.axios
        .post("ws/FindAnyoneCustomer/DeleteCustomer?Id=" + this.customerId)
        .then(() => {
          this.deletepopupActive = false;
          this.customerId = 0;
          this.domainName = null;
          this.$vs.notify({
            title: "Success",
            text: "Customer deleted successfully.",
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
          setTimeout(() => {
            this.errors.clear();
          }, 100);
          this.getCustomersList();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Erorr",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000,
          });
        });
    },
  },
};
</script>
